@tailwind base;
@tailwind components;
@tailwind utilities;

#nprogress .bar {
  background: #F59E0B !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #F59E0B, 0 0 5px #F59E0B !important;
}

#nprogress .spinner-icon {
  border-top-color: #F59E0B !important;
  border-left-color: #F59E0B !important;
}

@layer utilities {
  @variants responsive {
    .h-fit-content {
      height: fit-content !important;
    }

    .h-min{
      height: fit-content;
    }
  }
}

@media (min-width: 640px) {
  .sm\:h-screen-navbar {
    height: calc(100vh - 12rem) !important;
  }
}


@media (min-width: 768px) {
  .md\:h-screen-navbar {
    height: calc(100vh - 12rem) !important;
  }
}

.max-h-95 {
    max-height: 95%;
}

.h-95 {
  height: 95%;
}